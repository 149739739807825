import { Module } from "vuex";
import { IRootState } from "@monorepo/catalog/src/store";
import { IRole } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi } from "@monorepo/utils/src/api/convertFiltersToApi";
import { checkExistLibrary, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { IUser } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IUser";
import { changeOpenedIdPayloadValues } from "@monorepo/utils/src/utils/changeOpenedId";
import { cloneDeep } from "lodash";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { convertFiltersCustomToApi } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/convertFiltersToApi";
import { IUserListCardElement } from "@monorepo/utils/src/types/IUserListCardElement";
import axios from "axios";
import { IAuthorityGroup } from "@monorepo/authorization/src/views/Login/types/authResponse";
import { formRoles } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/formRoles";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { sortTableOnlyDateWithNonDate } from "@monorepo/utils/src/utils/sortTableOnlyDateWithNonDate";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { convertItemFromObjToString } from "@monorepo/utils/src/api/convertHistoryItemToApi";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { convertFiltersCustomToApi as convertFiltersArchiveToApi } from "@monorepo/catalog/src/views/ArchiveView/utils/convertFiltersToApi";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { elementStatuses } from "@monorepo/utils/src/variables/projectsData/fundView/elementStatuses";
import { IOikCardElement } from "@monorepo/catalog/src/views/OikView/types/oikCardElement";
import { IArchiveHierarchyElement } from "@monorepo/catalog/src/store/modules/archiveCatalogView";

type error = {
  response?: { data?: { message?: string; error?: { text: string } } };
};

const archiveFilters = (): ITableFiltersObject => ({
  sort: {
    code: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [elementStatuses[0].value],
  },
  initMessagesLength: 5000,
});

interface IAccessState {
  roles: IRole[]; // дефолтный список ролей
  certificates: { displayName: string; value: number }[];
  localRoles: IRole[]; // рабочий список ролей
  currentRoles: IRole[]; //  селекте в карточке ролей
  authorities: IAuthorityGroup[];
  filters: ITableFiltersObject;
  users: IUser[];
  totalUsers: number | null;
  totalRoles: number | null;
  isOpenFilters: boolean;
  totalLocalRoles: number | null;
  section?: Sections;
  cells: string[];
  sectionCells: ISection;
  infiniteId: string;
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  defaultTwoStepsAuth: boolean;
  libraries: {
    oik: IOikCardElement[];
    archives: IArchiveHierarchyElement[];
  };
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {},
  openedId: null, // opened modal for Roles
  openedUserId: null, // opened modal for Users
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [],
  },
  initMessagesLength: 50,
});

export const module: Module<IAccessState, IRootState> = {
  namespaced: true,
  state: (): IAccessState => ({
    roles: [],
    currentRoles: [],
    authorities: [],
    certificates: [],
    section: Sections.USERS_LIST,
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    isOpenFilters: false,
    localRoles: [],
    users: [],
    cells: [], // working with user table
    sectionCells: {} as ISection, // working with user table
    totalUsers: null,
    totalRoles: null,
    totalLocalRoles: null,
    isTableLoading: false,
    isLoadingToggleFilters: false,
    defaultTwoStepsAuth: false,
    libraries: {
      oik: [],
      archives: [],
    },
  }),
  mutations: {
    ...baseMutations,
    setFilters(state: IAccessState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    addSort(state: IAccessState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = sortTableOnlyDateWithNonDate(state.filters.sort, payload, ["createDate", "lastLoginTime"]);
    },
    setRoles(state: IAccessState, payload: { data: IRole[]; total: number }) {
      state.roles = payload.data;
      state.localRoles = cloneDeep(payload.data);
      state.totalRoles = payload.total;
      state.totalLocalRoles = payload.total;
    },
    clearFilters(state: IAccessState) {
      state.filters = defaultFilters();
    },
    setAuthorities(state: IAccessState, payload: IAuthorityGroup[]) {
      state.authorities = payload;
    },
    setCurrentRoles(state: IAccessState, payload: { data: IRole[] }) {
      state.currentRoles = payload.data;
    },
    setLocalRoles(state: IAccessState, payload: { data: IRole[]; total: number }) {
      state.localRoles = cloneDeep(payload.data);
      state.totalLocalRoles = payload.total;
    },
    addOpenedUserId(state: IAccessState, payload: null | string | number) {
      state.filters.openedUserId = payload;
    },
    changeOpenedUserId(state: IAccessState, payload: changeOpenedIdPayloadValues) {
      const selectedIndex = state.users.findIndex((element) => element.id === state.filters.openedUserId);

      if (payload === changeOpenedIdPayloadValues.UP) {
        state.filters.openedUserId = selectedIndex === 0 ? state.users[state.users.length - 1].id : state.users[selectedIndex - 1].id;
      }

      if (payload === changeOpenedIdPayloadValues.DOWN) {
        state.filters.openedUserId = selectedIndex === state.users.length - 1 ? state.users[0].id : state.users[selectedIndex + 1].id;
      }
    },
    setUsers(
      state: IAccessState,
      payload: {
        data: IUser[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.users = payload.isReset ? payload.data : state.users.concat(payload.data);
      state.totalUsers = payload.totalLength;
    },
    addRole(state: IAccessState, payload: IRole) {
      state.roles.push(payload);
    },
    removeRole(state: IAccessState, id: string) {
      state.roles = state.roles.filter((item) => item.id !== id);
    },
    refreshUsersData(state: IAccessState) {
      state.users = [];
    },
    blockUser(state: IAccessState, payload: string) {
      state.users = state.users.map((user) => {
        if (user.id === payload) {
          return {
            ...user,
            active: false,
          };
        } else {
          return user;
        }
      });
    },
    unblockUser(state: IAccessState, payload: string) {
      state.users = state.users.map((user) => {
        if (user.id === payload) {
          return {
            ...user,
            active: true,
          };
        } else {
          return user;
        }
      });
    },
    setTableLoading(state: IAccessState, payload: boolean) {
      state.isTableLoading = payload;
    },
    setDefaultTwoStepsAuth(state: IAccessState, payload: boolean) {
      state.defaultTwoStepsAuth = payload;
    },
    setCerts(state: IAccessState, payload: { displayName: string; value: number }[]) {
      state.certificates = payload;
    },
    addOikLib(state: IAccessState, payload: IOikCardElement[]) {
      state.libraries.oik =
        payload.map((oik) => {
          return { ...oik, label: oik.shortName };
        }) || [];
    },
    addArchives(state: IAccessState, payload: IArchiveHierarchyElement[]) {
      state.libraries.archives = (payload || []).reduce((result: IArchiveHierarchyElement[], item: IArchiveHierarchyElement) => {
        if (!item.isDeleted) {
          item.funds = item.funds.filter((fond) => !fond.isDeleted).map((fond) => ({ ...fond, parentId: item.id }));
          result = result.concat(item);
        }
        return result;
      }, []);
    },
  },
  actions: {
    ...baseActions,
    addOpenedId({ commit }, payload: null | string | number) {
      commit("addOpenedId", payload);
    },
    async getNotificationSettings(info, payload: string) {
      const { data } = await getQuery(`${QUERY_PATH.GET_SETTINGS}/${payload}`);
      return data || null;
    },
    async getVipnetCerts({ commit, dispatch }) {
      try {
        const urlCode = "VIPNET_URL";
        const loginCode = "VIPNET_LOGIN";
        const passwordCode = "VIPNET_PASSWORD";

        const url = await dispatch("getNotificationSettings", urlCode);
        const login = await dispatch("getNotificationSettings", loginCode);
        const password = await dispatch("getNotificationSettings", passwordCode);

        if (!url || !login || !password) {
          showNotification(`Для запроса сертификатов требуется заполнить поля vipnet в настройках системы`);
        }

        const { data } = await axios.post<Record<string, never>>(QUERY_PATH.GET_VIPNET_CERTS, { url, login, password });
        const list: unknown[] = data.data || [];
        commit("setCerts", list);
        showNotification(
          list.length ? "Список Vipnet сертификатов успешно загружен" : "Список Vipnet сертификатов не загружен",
          list.length ? NOTIFICATION_STATUS.SUCCESS : NOTIFICATION_STATUS.ERROR
        );
        return true;
      } catch (e) {
        showNotification("Ошибка загрузки списка сертификатов");
        return false;
      }
    },
    async getUserCardElement(info, id: string | number) {
      const { data } = await getQuery<IUserListCardElement>(`${QUERY_PATH.AUTHENTICATION_USER_LIST}/${id}`);
      return data;
    },
    async getAutocompleteByLogin(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.AUTHENTICATION_USER_LIST + `/by-username/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { username: string }) => ({
          ...item,
          titleValue: item.username,
        }));
      }
      return [];
    },
    async getAutocompleteByFullName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.AUTHENTICATION_USER_LIST + `/by-fullName/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { fullName: string }) => ({
          ...item,
          titleValue: item.fullName,
        }));
      }
      return [];
    },
    async getAutocompleteByEmail(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.AUTHENTICATION_USER_LIST + `/by-email/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { email: string }) => ({
          ...item,
          titleValue: item.email,
        }));
      }
      return [];
    },
    async getRoleUsers(info, { id, offset }: { id: string; offset: number }) {
      const { data, total: totalLength } = await getQuery<IUser[]>(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${id}/users`, {
        limit: info.state.filters.initMessagesLength,
        offset,
      });
      return { data, totalLength };
    },
    async saveCard(info, data: Record<string, unknown>): Promise<boolean> {
      try {
        const { data: resultData } = await axios[!data.id ? "post" : "put"](
          `${QUERY_PATH.AUTHENTICATION_USER_LIST}${!data.id ? "" : `/${data.id}`}`,
          data
        );
        return resultData;
      } catch (e) {
        showNotification(
          "Ошибка создания пользователя: " +
            ((e as unknown as error).response?.data?.error?.text || (e as unknown as error).response?.data?.message || "")
        );
        return false;
      }
    },
    addOpenedUserId({ commit, state, dispatch }, payload: null | string) {
      if (payload) {
        const userCard = (state.users ?? []).find((item: { id: string | number }) => item.id === payload) || { id: payload, username: "" };
        dispatch(
          "auth/saveUserHistory",
          {
            href: location.pathname,
            filters: convertItemFromObjToString(state.filters as unknown as Record<string, unknown>),
            section: state.section,
            item: convertItemFromObjToString({
              id: userCard.id,
              name: userCard.username || null,
            }),
          },
          { root: true }
        );
      }
      commit("addOpenedUserId", payload);
    },
    changeOpenedUserId({ commit, state, dispatch }, payload: changeOpenedIdPayloadValues) {
      if (payload) {
        const selectedIndex = state.users.findIndex((element) => element.id === state.filters.openedUserId);
        let id: string | number = "";

        if (payload === changeOpenedIdPayloadValues.UP) {
          id = selectedIndex === 0 ? state.users[state.users.length - 1].id : state.users[selectedIndex - 1].id;
        }

        if (payload === changeOpenedIdPayloadValues.DOWN) {
          id = selectedIndex === state.users.length - 1 ? state.users[0].id : state.users[selectedIndex + 1].id;
        }

        const userCard = (state.users ?? []).find((item: { id: string | number }) => item.id === id) || { id, username: "" };
        dispatch(
          "auth/saveUserHistory",
          {
            href: location.pathname,
            filters: convertItemFromObjToString(state.filters as unknown as Record<string, unknown>),
            section: state.section,
            item: convertItemFromObjToString({
              id: userCard.id,
              name: userCard.username || null,
            }),
          },
          { root: true }
        );
      }
      commit("changeOpenedUserId", payload);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resetPassword(info, id: string) {},
    async deleteRoleQuery(info, id: string) {
      return await axios.delete(getFullPath(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${id}`));
    },
    async blockUser(info, { id, blockUntil }: { id: number; blockUntil: string }) {
      try {
        await axios.put(getFullPath(`${QUERY_PATH.AUTHENTICATION_USER_LIST}/${id}/lock`), { blockUntil });
        info.commit("blockUser", id);
        return true;
      } catch (e) {
        showNotification("Ошибка блокировки пользователей");
        return false;
      }
    },
    async unblockUser(info, id: string) {
      try {
        await axios.put(getFullPath(`${QUERY_PATH.AUTHENTICATION_USER_LIST}/${id}/unlock`));
        info.commit("unblockUser", id);
        return true;
      } catch (e) {
        showNotification("Ошибка разблокировки пользователей");
        return false;
      }
    },
    async saveRoles(info, roles: { id: string | number }[]) {
      await Promise.all(
        roles.map((role) => {
          if (!role.id) {
            return axios.post(getFullPath(QUERY_PATH.AUTHENTICATION_ROLE_LIST), role);
          } else {
            return axios.put(getFullPath(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${role.id}`), role);
          }
        })
      )
        .then((results) => {
          const isSuccess = results.every((result) => result.status === 200);
          if (isSuccess) {
            showNotification("Настройки сохранены.", NOTIFICATION_STATUS.SUCCESS);
          }
        })
        .catch(function (error) {
          showNotification(error.response.data.message);
        });
      await info.dispatch("getRoles");
    },
    async deleteUserFromRole(info, payload: { roleId: string; userId: string }) {
      return await axios.delete(getFullPath(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${payload.roleId}/users/${payload.userId}`));
    },
    async deleteRoles({ dispatch }, payload: string) {
      await axios.delete(getFullPath(`${QUERY_PATH.AUTHENTICATION_ROLE_LIST}/${payload}`));
      dispatch("getRoles");
    },
    async getRoles({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      let authoritiesGroups = state.authorities || [];
      if (!authoritiesGroups?.length) {
        const { data } = await getQuery<IAuthorityGroup[]>(QUERY_PATH.AUTHENTICATION_ROLE_AUTHORITIES_GROUPS_LIST);
        authoritiesGroups = data;
        commit("setAuthorities", authoritiesGroups);
      }

      const { data: rolesList } = await getQuery<IRole[]>(QUERY_PATH.AUTHENTICATION_ROLE_LIST);
      const roles = formRoles(rolesList, authoritiesGroups);
      commit("setRoles", roles);
      commit("setTableLoading", false);
      return roles;
    },
    async getUserList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.users.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IUser[]>(QUERY_PATH.AUTHENTICATION_USER_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("setUsers", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.users || [], totalLength: state.totalUsers || 0 };
      }
      return { data: null };
    },
    async refreshUserList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.users.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<IUser[]>(QUERY_PATH.AUTHENTICATION_USER_LIST, params);
      if (data !== null) {
        commit("setUsers", { data: data || [], totalLength: totalLength || 0, isReset: true });
        return { data: state.users || [], totalLength: state.totalUsers || 0 };
      }
      return { data: null };
    },
    getExportUsers() {
      return;
    },
    async getCurrentRolesList({ commit }) {
      const { data } = await getQuery<IRole[]>(QUERY_PATH.AUTHENTICATION_ROLE_LIST);
      if (data !== null) {
        commit("setCurrentRoles", { data });
        return data;
      }
      return { data: [] };
    },
    addRole({ commit }, role) {
      commit("addRole", role);
    },
    removeRole({ commit }, id) {
      commit("removeRole", id);
    },
    refreshScroll({ commit }) {
      commit("refreshUsersData");
      setTimeout(() => {
        // TODO rewrite, error with infinite scroll this.$refs.infiniteLoader.getCurrentDistance() not refreshed to [] data
        commit("refreshInfiniteId");
      }, 100);
    },
    async getDefaultTwoStepsAuth({ commit }) {
      const { data } = await getQuery<{ data: boolean }>(QUERY_PATH.GET_TWO_STEPS_AUTH_SETTING);
      commit("setDefaultTwoStepsAuth", data ?? false);
    },
    async getArchiveHierarchy({ commit, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const { data } = await getQuery<IArchiveHierarchyElement[]>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_HIERARCHY}`).finally(() => {
        commit("setTableLoading", false);
      });
      return data;
    },
    async getArchiveHierarchyLib({ commit }) {
      const { data } = await getQuery<IArchiveHierarchyElement[]>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_HIERARCHY}`);
      commit("addArchives", data);
    },
    getOiks({ commit, state }) {
      const params = convertFiltersToApi(archiveFilters(), 0, convertFiltersArchiveToApi);
      const resultParams = {
        ...(params || {}),
        short: true,
      };
      checkExistLibrary(state.libraries.oik, async () => {
        const { data } = await getQuery<IArchiveElement[]>(QUERY_PATH.GET_CATALOG_OIK_LIST, resultParams);
        commit("addOikLib", data || []);
      });
    },
    async getUserAccesses(info, { userId }: { userId: string }) {
      const { data } = await getQuery<IArchiveElement[]>(`${QUERY_PATH.AUTHENTICATION_USER_ACCESSES}/${userId}`);
      return data || { archiveIds: [], fundIds: [], oikIds: [] };
    },
    async setUserAccesses(info, payload: { userId: string; fundIds: string[]; archiveIds: string[]; oikIds: string[] }) {
      try {
        await axios.post(QUERY_PATH.AUTHENTICATION_USER_ACCESSES, payload);
      } catch (error) {
        showNotification(error.response.data.message);
      }
    },
  },
  getters: {
    ...baseGetters,
    roles: (state: IAccessState) => state.roles,
    certificates: (state: IAccessState) => state.certificates,
    currentRoles: (state: IAccessState) => state.currentRoles,
    localRoles: (state: IAccessState) => state.localRoles,
    authorities: (state: IAccessState) => state.authorities,
    selectedIds: (state: IAccessState) => state.filters.selectedIds,
    users: (state: IAccessState) => state.users || [],
    totalUsers: (state: IAccessState) => state.totalUsers || 0,
    totalRoles: (state: IAccessState) => state.totalRoles || 0,
    totalLocalRoles: (state: IAccessState) => state.totalLocalRoles || 0,
    openedUserId: (state: IAccessState) => state.filters.openedUserId,
    isTableLoading(state: IAccessState) {
      return state.isTableLoading;
    },
    defaultTwoStepsAuth: (state: IAccessState) => state.defaultTwoStepsAuth,
    oiks: (state: IAccessState) => state.libraries.oik || [],
    archivesTree: (state: IAccessState) => state.libraries.archives || [],
  },
};
